.d-slider1{
    position: relative;
    .swiper-button{
        width: 30px;
        height: 30px;
        position: absolute;
        &.swiper-button-next {
            right: 12px;
            left:auto;
            top:65px;
            &:after {
                content: 'next';
                font-size: 2.00rem;
                    ;
                color: $body-color;
            }
        }
        &.swiper-button-prev {
            &:after {
                content: 'prev';
                font-size: 2.00rem;
                    ;
                color: $body-color;
            }
            right:auto;
                        left:-1px;
            top:65px;
        }
    }
    
}

